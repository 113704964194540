@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..400&display=swap');

* {
  box-sizing: content-box;
  font-family: 'Inter';
}

.application-parent {
  display: flex;
  flex-direction: column;
  background-color: #f5f5dc;
}

Header {
  background: rgb(227, 84, 13);
  padding: 0 2%;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
}

.Body {
  flex-grow: 1;
  height: calc(100vh - 100px);
}

Footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #222;
  text-align: center;
  color: #fff;
  width: 100%;
}
