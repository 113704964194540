.login-form-container,
.registration-form-container {
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-form,
.registration-form {
  width: 100%;
  max-width: 350px;
}

.login-greeting,
.registration-greeting {
  text-align: center;
  display: flex;
  justify-content: center;
}

.login-form .form-control,
.registration-form .form-control {
  background-color: #f5f5dc;
}

.login-button-wrapper,
.registration-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.login-button,
.registration-button {
  width: 100%;
  margin-bottom: 5px;
}

.modal-header {
  display: flex;
  justify-content: center;
}

.modal-title {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 1.8rem;
}

.modal-body {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
}

.data-disclaimer {
  display: flex;
  justify-content: right;
  font-size: 0.6rem;
}

.authenticationModal-button-container {
  display: flex;
  width: 100%;
  gap: 1%;
}
