.nav-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 60%;
}

.home-button-wrapper {
  display: flex;
  justify-content: center;
}

.home-button-wrapper Button {
  margin: 0% 5%;
}

.nav-button-container {
  display: flex;
  align-items: center;
}

.nav-button {
  margin: 5px;
}

#icon * {
  display: flex;
  align-items: center;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.hero {
  height: calc(100vh - 100px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background-size: cover;
  overflow: auto;
}

.hero_title {
  font-size: 100px;
  font-family: 'Merriweather', serif;
  text-align: center;
}

.hero_subtitle {
  font-size: 40px;
  font-style: italic;
  text-align: center;
}

.hero_content {
  color: #fff;
  box-sizing: content-box;
}

.hero_content a {
  display: inline-block;
  padding: 15px 30px;
  margin: 0 10px;
  text-decoration: none;
}
