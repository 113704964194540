.query-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5dc;
  min-height: calc(100vh - 130px);
  width: 100%;
  padding: 1.5%;
  flex-grow: 1;
}

.query-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  flex-direction: col;
}

.query-bar {
  flex-direction: row;
  justify-content: center;
  align-items: horizontal;
  margin: 0px 15px;
  background-color: #fff;
  border-radius: 5px;
}

.search-result-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.search-result-container {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 1.5% 2%;
}

.search-results-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 2% 0 0;
  flex-grow: 1;
}

.search-results-text-button {
  width: 100%;
}

.search-results-graph-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30%;
  width: 100%;
  /* padding: 0 0.9% 0 0; */
}

.search-results-graph {
  width: 100%;
}

.search-results-register-text {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.returned-country-information-B,
.returned-country-information-A {
  display: flex;
  flex-direction: row;
}

.returned-country-information-B-item1 {
  display: flex;
  justify-content: left;
  align-items: left;
  width: 70%;
}

.returned-country-information-B-item2 {
  display: flex;
  justify-content: right;
  align-items: right;
  width: 30%;
}

.returned-country-information-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.returned-country-information-text {
  display: flex;
  justify-content: left;
  flex-direction: column;
  width: 80%;
}

.returned-country-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ag-grid.container {
  width: 100%;
}

#basic-typeahead-single .form-control {
  background-color: #fff;
}

.modal-header {
  display: flex;
  justify-content: center;
}

.modal-title {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 1.8rem;
}

.modal-body {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
}

.modal-body p {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  padding-top: 10px;
}

.search-results-static {
  width: 65%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 2%;
}

.search-results-reviews {
  width: 20%;
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
}

.search-results-text {
  width: 15%;
  display: flex;
  flex-direction: column;
}
.search-results-weather-container {
  display: flex;
  height: 100%;
  align-items: end;
}

.comment-button-container {
  margin: 10px 0 0 0;
  gap: 2%;
  flex-direction: row;
  display: flex;
}

.comment-button-container Button {
  width: 50%;
}

.reviews-box {
  display: flex;
  flex-direction: column;
  padding: 5% 0;
}

.reviews-container {
  overflow-y: auto;
  max-height: 300px;
}

.result-date {
  font-size: smaller;
}
